import { Dialog } from '@headlessui/react'
//import { CheckIcon } from '@heroicons/react/outline'
import { MiniGrid } from '../mini-grid/MiniGrid'
import { ShareStatus } from '../../lib/share'
import { BaseModal } from './BaseModal'
import { getShareData, capitalizeFirstLetter } from '../../lib/param'
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean
  handleClose: () => void
  guesses: string[]
  handleShare: () => void
}

export const WinModal = ({
  isOpen,
  handleClose,
  guesses,
  handleShare,
}: Props) => {
  const shareData = getShareData();
  const { t } = useTranslation();
  let info;
  
  const owner_name = shareData.name;
  let gameName = capitalizeFirstLetter(t("home.title"));
  let gameNameWithOwner = gameName;
  if (owner_name) {
    gameNameWithOwner = t("home.caption").replace('Xxx', owner_name.toString())
  }
  const text1 = t("share.text1") + ", " + capitalizeFirstLetter(t("share.text2")) + "!";
  const text3 = t("share.text3")

  if(shareData.name) {
    let content = t("alert.answer_with_owner");
    content = content.replace('Xxx', '<span>' + shareData.name + '</span>') + ' “' + shareData.word.toUpperCase() + '”';
    info = (<Dialog.Title as="p"
      className="text-lg leading-6 font-medium text-gray-900 win-content"
      dangerouslySetInnerHTML={{__html:content}}
    ></Dialog.Title>)
  } else {
    info = (<Dialog.Title as="p"
      className="text-lg leading-6 font-medium text-gray-900 win-content"
    >{t("alert.answer")} “{shareData.word}”</Dialog.Title>)
  }
  var shareGuesses = guesses.concat([]);
  /* while (shareGuesses.length > 0 && shareGuesses.length < 6){
    shareGuesses.push(new Array(guesses[0].length).fill('0').join(''))
  } */
  return (
    <BaseModal title={t("alert.you_won")} isOpen={isOpen} handleClose={handleClose} showClose={false}>
      <div>
        {/* <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
          <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
        </div> */}
        <div className="mt-3 text-center sm:mt-5">
            {info}
          <div className="mt-2">
            <MiniGrid guesses={shareGuesses} />
            {/* <p className="text-sm text-gray-500">Great job.</p> */}
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6">
        <button
          type="button"
          className="share-btn"
          style={{ backgroundImage: "url('/img/" + t("share.button_img") + "')" }}
          onClick={() => {
            ShareStatus(gameNameWithOwner, guesses, text1, text3)
            handleShare()
          }}
        ></button>
      </div>
      <p className="custom-link">
        <a href="https://bit.ly/Wordle_daily">{t("share.customize")} <img src="/img/arrow_right.png" alt="" /></a>
      </p>
      
    </BaseModal>
  )
}
//           className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"

