import { WORDS } from '../constants/wordlist'
import { VALIDGUESSES } from '../constants/validGuesses'
import { getShareData } from './param'

export const isWordInWordList = (word: string) => {
  return (
    word.toUpperCase() === solution ||
    WORDS.includes(word.toLowerCase()) ||
    VALIDGUESSES.includes(word.toLowerCase())
  )
}

export const isWinningWord = (word: string) => {
  return solution === word
}

export const getWordParam = () => {
  const shareData = getShareData();
  const word = shareData.word || '';
  const name = shareData.name;
  const hint = shareData.hint;

  return {
    solution: word.toUpperCase(),
    solutionIndex: 0,
    shareName: name,
    shareHint: hint,
  }
}

export const { solution, solutionIndex, shareName, shareHint } = getWordParam()



/*   // January 1, 2022 Game Epoch
  const epochMs = 1641013200000
  const now = Date.now()
  const msInDay = 86400000
  const index = Math.floor((now - epochMs) / msInDay)

  solution: WORDS[index].toUpperCase(), */