import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../lib/param'
//import { escape } from 'querystring';

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  const { t } = useTranslation();
  let contents = t('help.content').replace('WORDLE', '<span>WORDLE</span>').split('\n');
  contents = contents.filter(function(e){return e}); 
  const example1 = t('help.exp1');
  const example2 = t('help.exp2');
  const example3 = t('help.exp3');
  let exp1_desc_arr = t('help.exp1_desc').split(' ');
  exp1_desc_arr[0] = "<span>" + exp1_desc_arr[0] + "</span>";
  let exp2_desc_arr = t('help.exp2_desc').split(' ');
  exp2_desc_arr[0] = "<span>" + exp2_desc_arr[0] + "</span>";
  let exp3_desc_arr = t('help.exp3_desc').split(' ');
  exp3_desc_arr[0] = "<span>" + exp3_desc_arr[0] + "</span>";
  const exp1_desc = exp1_desc_arr.join(' ');
  const exp2_desc = exp2_desc_arr.join(' ');
  const exp3_desc = exp3_desc_arr.join(' ');

  return (
    <BaseModal title={t('help.title')} isOpen={isOpen} handleClose={handleClose}>
      {contents.map(function(object, i){
          return <p className="text-sm text-gray-500 how-to-play-content"
          key={object}
          dangerouslySetInnerHTML={{__html:object}}>
          </p>
      })}
      <hr/>
      <p className="how-to-play-content"><b>{capitalizeFirstLetter(t('help.example'))}:</b></p>

      <div className="flex justify-center mb-1 mt-4">
        {example1.split('').map(function(letter, i){
          let cell;
          if(i===0){
            cell = <Cell value={letter} status="correct"
            key={letter}
            />
          }else {
            cell = <Cell value={letter}
            key={letter}
            />
          }
          return cell
        })}
      </div>
      <p className="text-sm text-gray-500 how-to-play-content" dangerouslySetInnerHTML={{__html:exp1_desc}}>
        {/* <span>“W”</span> is in the word and in the correct spot. */}
      </p>

      <div className="flex justify-center mb-1 mt-4">
        {example2.split('').map(function(letter, i){
          let cell;
          if(i===1){
            cell = <Cell value={letter} status="present"
            key={letter}
            />
          }else {
            cell = <Cell value={letter}
            key={letter}
            />
          }
          return cell
        })}
      </div>
      <p className="text-sm text-gray-500 how-to-play-content" dangerouslySetInnerHTML={{__html:exp2_desc}}>
        {/* <span>“I”</span> is in the word but in the wrong spot. */}
      </p>

      <div className="flex justify-center mb-1 mt-4">
        {example3.split('').map(function(letter, i){
          let cell;
          if(i===3){
            cell = <Cell value={letter} status="absent"
            key={letter}
            />
          }else {
            cell = <Cell value={letter}
            key={letter}
            />
          }
          return cell
        })}
      </div>
      <p className="text-sm text-gray-500 how-to-play-content" dangerouslySetInnerHTML={{__html:exp3_desc}}>

        {/* <span>“U”</span> is not in the word but in any spot. */}
      </p>
    </BaseModal>
  )
}
