import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getUrlParam } from './lib/param'

// import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en-us.json'
/* import es from './locales/es-mx.json'
import pt from './locales/pt-br.json'
import ru from './locales/ru-ru.json'
import it from './locales/it-it.json'
import fr from './locales/fr-fr.json'
import de from './locales/de-ge.json'
import zh from './locales/zh-cn.json' */

const language = getUrlParam("lang") || "en"

const resources = {
  en: {
    translation: en
  },
/*   es: {
    translation: es
  },
  pt: {
    translation: pt
  },
  fr: {
    translation: fr
  },
  it: {
    translation: it
  },
  de: {
    translation: de
  },
  ru: {
    translation: ru
  },
  zh: {
    translation: zh
  }, */
};

//i18n.use(LanguageDetector) //嗅探当前浏览器语言 zh-CN
i18n.use(initReactI18next) // 将 i18n 向下传递给 react-i18next
  .init({ //初始化
    resources, //本地多语言数据
    fallbackLng: "en", //默认的语言
    lng: language,
    detection: {
      caches: ['localStorage', 'sessionStorage', 'cookie'],
    }
  })

export default i18n