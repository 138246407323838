import {Buffer} from 'buffer';

export const getUrlParam = (name: string) => {
    // 获取URL参数
    const url = window.location.href;
    const query = url.split('?')[1];
    const param_arr = query.split('&');
    var value = null;
    for(let element of param_arr) {
        if(element.split('=')[0] === name){
          value = element.split('=')[1];
          break
        }
    }
    return value
}

type ShareDataState = {
  word: String
  hint: String
  name: String
}

export const getShareData = () => {
  const param_d = getUrlParam("d") || '';
  const decoded = Buffer.from(param_d, "base64").toString();
  const shareData = JSON.parse(decoded);
  return shareData as ShareDataState
}

export const capitalizeFirstLetter = (s:string) => {
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}