//import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import classNames from 'classnames'

type Props = {
  isOpen: boolean
  message: string
}

export const Guide = ({ isOpen, message }: Props) => {
  const classes = classNames(
    'fixed top-80 left-1/2 bg-gray-600 transform -translate-x-1/2 max-w-xs w-full shadow-lg rounded-3xl pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden',
  )
  return (
    <Transition
      show={isOpen}
      enter="ease-out duration-300 transition"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className={classes}>
        <div className="p-4">
          <p className="text-center font-medium text-white">
            {message}
          </p>
        </div>
      </div>
      <div className="fixed top-96 w-full animate-bounce left-0">
          <img className="w-6 img-arraw-down" src="/img/arrow_down.png" alt="⬇" />
      </div>
    </Transition>
  )
}
