import { QuestionMarkCircleIcon } from '@heroicons/react/outline'
//import { ChartBarIcon } from '@heroicons/react/outline'
import { useState, useEffect } from 'react'
//import i18n from './i18n'
import { useTranslation } from 'react-i18next';
import { Alert } from './components/alerts/Alert'
import { Guide } from './components/alerts/Guide'
import { Grid } from './components/grid/Grid'
import { Ad } from './components/ad/Ad'
import { Keyboard } from './components/keyboard/Keyboard'
import { AboutModal } from './components/modals/AboutModal'
import { InfoModal } from './components/modals/InfoModal'
import { WinModal } from './components/modals/WinModal'
//import { StatsModal } from './components/modals/StatsModal'
import { isWordInWordList, isWinningWord, solution } from './lib/words'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import { getShareData, capitalizeFirstLetter } from './lib/param'
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
} from './lib/localStorage'
import './App.css'

function App() {
  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isWinModalOpen, setIsWinModalOpen] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)
  const [isNotEnoughLetters, setIsNotEnoughLetters] = useState(false)
  //const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isWordNotFoundAlertOpen, setIsWordNotFoundAlertOpen] = useState(false)
  const [isGameLost, setIsGameLost] = useState(false)
  const [shareComplete, setShareComplete] = useState(false)
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage()
    if (loaded?.solution !== solution) {
      return []
    }
    const gameWasWon = loaded.guesses.includes(solution)
    if (gameWasWon) {
      setIsGameWon(true)
    }
    if (loaded.guesses.length === 6 && !gameWasWon) {
      setIsGameLost(true)
    }
    return loaded.guesses
  })
  const [isShowGuide, setIsShowGuide] = useState<boolean>(() => {
    return guesses.length === 0
  })

  const [stats, setStats] = useState(() => loadStats())
  const { t } = useTranslation();

  useEffect(() => {
    saveGameStateToLocalStorage({ guesses, solution })
  }, [guesses])

  useEffect(() => {
    if (isGameWon) {
      setIsWinModalOpen(true)
    }
  }, [isGameWon])

  const onChar = (value: string) => {
    if (currentGuess.length < 5 && guesses.length < 6 && !isGameWon) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
    setIsShowGuide(false);
  }

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1))
  }

  const onEnter = () => {
    if (!(currentGuess.length === 5) && !isGameLost) {
      setIsNotEnoughLetters(true)
      return setTimeout(() => {
        setIsNotEnoughLetters(false)
      }, 2000)
    }

    if (!isWordInWordList(currentGuess)) {
      setIsWordNotFoundAlertOpen(true)
      return setTimeout(() => {
        setIsWordNotFoundAlertOpen(false)
      }, 2000)
    }

    const winningWord = isWinningWord(currentGuess)

    if (currentGuess.length === 5 && guesses.length < 6 && !isGameWon) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')

      if (winningWord) {
        setStats(addStatsForCompletedGame(stats, guesses.length))
        return setIsGameWon(true)
      }

      if (guesses.length === 5) {
        setStats(addStatsForCompletedGame(stats, guesses.length + 1))
        setIsGameLost(true)
      }
    }
  }

  const shareData = getShareData();
  const owner_name = shareData.name;
  const hint = shareData.hint;
  let gameName = capitalizeFirstLetter(t("home.title"));
  let gameNameWithOwner = gameName;
  let gameNameWithOwnerTag = gameName;
  if (owner_name) {
    gameNameWithOwner = t("home.caption").replace('Xxx', owner_name.toString())
    gameNameWithOwnerTag = t("home.caption").replace('Xxx', '<span class="share-name">'+owner_name+'</span>')
  }

  useEffect(() => {
    document.title = gameNameWithOwner
  }, [gameNameWithOwner]);

  const title = owner_name ?
      (<h1 className="text-xl grow font-bold"
        dangerouslySetInnerHTML={{__html:gameNameWithOwnerTag}}></h1>) :
      (<h1 className="text-xl grow font-bold">{gameName} </h1>)
  const hint_tip = hint ?
      (<div className="share-hint">{t("home.hint")} {hint}</div>) :
      (<div className="share-hint share-hint-empty"></div>)

  return (
    <div className="py-4 max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div className="flex w-80 mx-auto items-center mb-8 title-bar">
        {title}
        <QuestionMarkCircleIcon
          className="h-6 w-6 cursor-pointer"
          onClick={() => setIsInfoModalOpen(true)}
        />
        {/* <ChartBarIcon
          className="h-6 w-6 cursor-pointer"
          onClick={() => setIsStatsModalOpen(true)}
        /> */}
      </div>
      {hint_tip}
      <Grid guesses={guesses} currentGuess={currentGuess} />
      <Keyboard
        onChar={onChar}
        onDelete={onDelete}
        onEnter={onEnter}
        guesses={guesses}
      />
      <WinModal
        isOpen={isWinModalOpen}
        handleClose={() => setIsWinModalOpen(false)}
        guesses={guesses}
        handleShare={() => {
          //setIsWinModalOpen(false)
          setShareComplete(true)
          return setTimeout(() => {
            setShareComplete(false)
          }, 2000)
        }}
      />
      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />
      {/* <StatsModal
        isOpen={isStatsModalOpen}
        handleClose={() => setIsStatsModalOpen(false)}
        gameStats={stats}
      /> */}
      <AboutModal
        isOpen={isAboutModalOpen}
        handleClose={() => setIsAboutModalOpen(false)}
      />

      {/* <button
        type="button"
        className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
        onClick={() => setIsAboutModalOpen(true)}
      >
        About this game
      </button> */}

      {/* <div><img src="/img/ad_bg.jpg" alt="jump to download page."/></div> */}
      <Ad status={isGameWon} />

      <Guide message={t("alert.guide")} isOpen={isShowGuide} />
      <Alert message={t("alert.not_enough_letter")} isOpen={isNotEnoughLetters} />
      <Alert message={t("alert.not_in_wordlist")} isOpen={isWordNotFoundAlertOpen} />
      <Alert
        message={t("alert.you_failed") + " " + solution}
        isOpen={isGameLost}
      />
      <Alert
        message={t("alert.link_copied")}
        isOpen={shareComplete}
        variant="success"
      />
      <img src={"/img/" + t("share.button_img")} className="preload-img" alt="pre-load"/>
    </div>
  )
}

export default App
